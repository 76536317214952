import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import ReactPixel from 'react-facebook-pixel';
import Color from 'color-thief-react';
import api from '../../services/api';

import { useAuth } from '../../context/auth';
import { useOrder } from '../../context/order';
import { useCart } from '../../context/cart';

import LogoTakeatCinza from '../../assets/images/logo_takeat_cinza.png';

import {
  Container,
  Content,
  Footer,
  ConfirmButton,
  ModalFooter,
  ModalTitle,
  PoweredBy,
  RestaurantName,
  RestaurantLogo,
  MessageOne,
} from './styles';

import { Button, LoadingPage } from 'ui-kit-takeat';
import { Helmet } from 'react-helmet';
import FlagsArea from '../../components/FlagsArea';
import { toast } from 'react-toastify';
import { uuid } from 'uuidv4';
import { FaStar } from 'react-icons/fa';

const WelcomeFromCode = props => {
  const { restaurant_name, restaurant_code } = props.match.params;
  const [pixelId, setPixelId] = useState(null)
  const [pixelId2, setPixelId2] = useState(null)
  const searchFoodpark = props.location?.search;

  const [isLoading, setIsLoading] = useState(false);
  const [locationLimited, setlocationLimited] = useState('');
  const [restaurantId, setRestaurantId] = useState('');
  const [modalPhrase, setModalPhrase] = useState('');
  const [modalAction, setModalAction] = useState([]);
  const [restaurantAvatarState, setRestaurantAvatarState] = useState('');
  const [restaurantFantasyName, setRestaurantFantasyName] = useState('');
  const [restaurantGreeting, setRestaurantGreeting] = useState('');
  const [isDeliveryActive, setIsDeliveryActive] = useState('');
  const [isDeliveryAllowed, setIsDeliveryAllowed] = useState('');
  const [isWithdrawallActive, setIsWithdrawalActive] = useState('');
  const [isWithdrawallAllowed, setIsWithdrawalAllowed] = useState('');
  const [menuTranslationsEnabled, setTranslationsEnabled] = useState(false);

  const [isSchedulingActive, setIsSchedulingActive] = useState('');
  const history = useHistory();

  const {
    setRestaurant,
    setTable,
    setRestaurantAvatar,
    setRestaurantLocationLimited,
    setRestaurantIsOpen,
    setUserRecentlyProduct,
    setRestaurantAcceptsPix,
    setRestaurantAcceptsPaytime,
    setRestaurantUseCommand,
    setIsDeliveryByDistance,
    setRestaurantAddress,
    setRestaurantWithdrawalTime,
    setRestaurantDeliveryTime,
    setClientPaySms,
    setIsSmsServiceOptional,
    setRestaurantHasSmsService,
    setRestaurantAcceptsWithdrawal,
    setRestaurantAcceptsDelivery,
    setRestaurantDeliveryActive,
    setOrderSchedulingActive,
    setRestaurantWithdrawalActive,
    setRestaurantDeliveryPayments,
    setRestaurantAcceptsPaymentAtWithdrawal,
    setRestaurantOnlyQrcode,
    setHasFoodpark,
    setFoodparkKey,
    setMinimumPrices,
    setMenuTranslationsEnabled,
    setRestaurantRequiresUserCEP,
    setRequireTableNumber,
    setRedirectType,
    setKeyClube,
    setClube,
    setIsPixelInitialized
  } = useAuth();
  const { closeOrder } = useOrder();
  const { closeCart, closeCartPixel } = useCart();
  const [modalIsOpen, setIsOpen] = useState(false);

  Modal.setAppElement('#root');

  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '7px',
    },
  };

  const getKeyClube = async () => {
    try {
      const response = await api.get(`/public/clube/store/${restaurantId}`);
      if (!response.data.notFound) {
        setClube(response.data);
        setKeyClube(response.data.key);
      }
    } catch (err) {
      console.log('Erro ao buscar clube', err);
    }
  };

  function closeModal() {
    setIsOpen(false);

    modalAction[0]();
  }

  function openModal(phrase) {
    setIsOpen(true);
    setModalPhrase(phrase);
  }

  useEffect(() => {
    closeOrder();
    closeCartPixel();
    closeCart();
  }, [closeOrder, closeCart, closeCartPixel]);

  useEffect(() => {
    setUserRecentlyProduct('');
  });

  const [restaurantActive, setRestaurantActive] = useState(true);

  useEffect(() => {
    async function fetchRestaurantData() {
      setIsLoading(true);
      const response = await api.get(`public/restaurant/${restaurant_name}`);

      const {
        name,
        id,
        fantasy_name,
        has_service_tax,
        service_tax,
        is_location_limited,
        opened,
        instagram,
        has_pix,
        has_credit_card,
        table_type,
        currency,
        adress,
        payment_methods,
        phone,
        greeting_message,
        is_order_scheduling_active,
        only_qrcode,
        token_clube,
        has_clube,
        pixel_id,
        pixel_id2,
        enable_translations,
        is_active,
        meta_access_token,
        meta_access_token2,
      } = response.data;

      const {
        is_delivery_by_distance,
        delimit_by_area,
        time_to_delivery,
        time_to_withdrawal,
        has_sms_service,
        is_sms_service_optional,
        client_pay_sms,
        is_withdrawal_allowed,
        is_delivery_active,
        is_delivery_allowed,
        is_withdrawal_active,
        payment_at_withdrawal,
        cep_required,
        delivery_minimum_price,
        withdrawal_minimum_price,
      } = response.data.delivery_info;

      setRestaurantActive(is_active);

      setMinimumPrices(delivery_minimum_price, withdrawal_minimum_price);

      setPixelId(pixel_id)
      setPixelId2(pixel_id2)

      if (response.data.avatar) {
        const { url } = response.data.avatar;
        setRestaurantAvatarState(url);
        setRestaurantAvatar(url);
      }

      if (has_pix) {
        setRestaurantAcceptsPix(true);
      } else {
        setRestaurantAcceptsPix(false);
      }

      if (has_credit_card) {
        setRestaurantAcceptsPaytime(true);
      } else {
        setRestaurantAcceptsPaytime(false);
      }

      // if (table_type === 'Comanda') {
      //   setRestaurantUseCommand(true);
      // } else {
      //   setRestaurantUseCommand(false);
      // }

      const subscriptionIdFb = uuid();
      const subscriptionIdFb2 = uuid();

      setRestaurant(
        id,
        name,
        fantasy_name,
        has_service_tax,
        service_tax,
        instagram,
        currency,
        phone,
        token_clube,
        has_clube,
        pixel_id,
        pixel_id2,
        is_active,
        meta_access_token,
        meta_access_token2,
        subscriptionIdFb,
        subscriptionIdFb2,
      );

      setRestaurantAddress(adress);

      setRestaurantGreeting(greeting_message);
      setRestaurantAcceptsWithdrawal(is_withdrawal_allowed);
      setIsWithdrawalActive(is_withdrawal_active);
      setIsWithdrawalAllowed(is_withdrawal_allowed);
      setRestaurantAcceptsPaymentAtWithdrawal(payment_at_withdrawal);
      setRestaurantRequiresUserCEP(cep_required);
      setRestaurantAcceptsDelivery(is_delivery_allowed);
      setRestaurantDeliveryActive(is_delivery_active);
      setOrderSchedulingActive(is_order_scheduling_active);

      setIsSchedulingActive(is_order_scheduling_active);
      setIsDeliveryActive(is_delivery_active);
      setIsDeliveryAllowed(is_delivery_allowed);
      setRestaurantWithdrawalActive(is_withdrawal_active);
      setRestaurantWithdrawalTime(time_to_withdrawal);
      setRestaurantDeliveryTime(time_to_delivery);
      setClientPaySms(client_pay_sms);
      setIsSmsServiceOptional(is_sms_service_optional);
      setRestaurantHasSmsService(has_sms_service);
      setRestaurantLocationLimited(is_location_limited);
      setlocationLimited(is_location_limited);
      setIsDeliveryByDistance(is_delivery_by_distance || delimit_by_area);
      setRestaurantIsOpen(opened);
      setRestaurantId(id);
      setRestaurantFantasyName(fantasy_name);
      setRestaurantOnlyQrcode(only_qrcode);
      setMenuTranslationsEnabled(enable_translations);
      setTranslationsEnabled(enable_translations);
      setIsLoading(false);



      if (searchFoodpark && searchFoodpark !== '') {
        const foodpark = searchFoodpark.replace('?foodpark=', '');
        if (
          foodpark &&
          foodpark !== '' &&
          foodpark !== ' ' &&
          foodpark !== 'null'
        ) {
          setHasFoodpark(true);
          setFoodparkKey(foodpark);
        }
      } else {
        setHasFoodpark(false);
        setFoodparkKey('');
      }

      const paymentsFiltered = payment_methods
        .filter(
          method =>
            method.keyword !== 'online_ifood' && method.keyword !== 'prazo',
        )
        .sort((a, b) => {
          return a.id - b.id;
        });

      setRestaurantDeliveryPayments(paymentsFiltered);
    }
    fetchRestaurantData();
  }, [
    restaurant_name,
    setRestaurant,
    setRestaurantAvatar,
    setRestaurantLocationLimited,
    setRestaurantIsOpen,
    setRestaurantUseCommand,
    setRestaurantAcceptsPix,
    setRestaurantAcceptsPaytime,
    setRestaurantAcceptsDelivery,
    setRestaurantAcceptsWithdrawal,
    setClientPaySms,
    setIsSmsServiceOptional,
    setIsDeliveryByDistance,
    setRestaurantAddress,
    setRestaurantDeliveryActive,
    setRestaurantHasSmsService,
    setRestaurantWithdrawalTime,
    setRestaurantDeliveryTime,
    setRestaurantDeliveryPayments,
    setRestaurantWithdrawalActive,
    setRestaurantAcceptsPaymentAtWithdrawal,
    setRestaurantOnlyQrcode,
    setRestaurantRequiresUserCEP,
  ]);

  useEffect(() => {
    api
      .get(`public/require-table-number/${restaurant_name}`)
      .then(res => {
        setRequireTableNumber(res.data.require_table_number);
      })
      .catch(error => {
        console.log(error);
      });
  }, [restaurant_name, setRequireTableNumber]);

  useEffect(() => {
    api
      .get(`public/${restaurant_name}/${restaurant_code}`)
      .then(res => {
        const { table_number, table_type, id } = res.data.table;

        setTable(table_number, restaurant_code, table_type, id);

        //pixel init
        const advancedMatching = {};
        const options = {
          autoConfig: true,
          debug: false,
          samesite: 'none',
          secure: true,
          first_party_cookies: ['_fbp'],
        };

        if (pixelId && table_type !== 'delivery') {
          ReactPixel.init(pixelId, advancedMatching, options);
          setIsPixelInitialized("true");
        }

        if (pixelId2 && table_type === 'delivery') {
          ReactPixel.init(pixelId2, advancedMatching, options);
          setIsPixelInitialized("true");
        }

        setRedirectType('table');

        if (table_type === 'command') {
          setRestaurantUseCommand(true);
        } else {
          setRestaurantUseCommand(false);
        }
      })
      .catch(error => {
        if (!error.response?.ok && error.response) {
          switch (error.response.data.errorType) {
            case 'service_unavailable':
              history.push(`/app/systemError`);

              break;
            default:
              openModal(
                'Código não encontrado na mesa, leia o qrCode novamente',
              );
              setModalAction([
                () => {
                  history.push(`/auto/${restaurant_name}`);
                },
              ]);
              break;
          }
        } else {
          console.log(error);
        }
      });
  }, [restaurant_code, restaurant_name, setRestaurant, setTable, history, pixelId, pixelId2]);

  useEffect(() => {
    if (restaurantId) {
      getKeyClube();
    }
  }, [restaurantId]);

  function handleNavigate() {
    if (!restaurantActive) {
      toast.error('Restaurante não está ativo.');
      return;
    }
    if (locationLimited.toString() === 'true') {
      history.push('/app/needLocation');
    } else {
      history.push(`/menu/${restaurantId}`);
    }
  }

  return isLoading ? (
    <LoadingPage duration={3} text="Carregando..." />
  ) : (
    <Container>
      <Helmet>
        <title>{restaurantFantasyName}</title>
        <meta
          name="description"
          content={
            restaurantGreeting ||
            'Aproveite nosso cardápio e tenha um excelente apetite.'
          }
        />
        <meta property="og:image" content={restaurantAvatarState} />
      </Helmet>
      <Content>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Descrição do Item"
        >
          <ModalTitle>
            <p>{modalPhrase}</p>
          </ModalTitle>
          <ModalFooter>
            <ConfirmButton onClick={closeModal}>Ok, entendi</ConfirmButton>
          </ModalFooter>
        </Modal>

        <PoweredBy>
          Desenvolvido por <img src={LogoTakeatCinza} />
        </PoweredBy>

        {menuTranslationsEnabled && <FlagsArea />}

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {restaurantAvatarState && (
            <Color
              src={restaurantAvatarState}
              crossOrigin="anonymous"
              format="hex"
            >
              {({ data }) => (
                <RestaurantLogo style={{ backgroundColor: data || '#fff' }}>
                  <img src={restaurantAvatarState} alt="logo" />
                </RestaurantLogo>
              )}
            </Color>
          )}

          <RestaurantName>
            <h1>{` ${restaurantFantasyName}`}</h1>
          </RestaurantName>
        </div>

        <MessageOne>
          <p>
            {restaurantGreeting ||
              'Aproveite nosso cardápio e tenha um excelente apetite.'}
          </p>
        </MessageOne>
      </Content>
      <Footer>
        <Button
          title={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 10,
              }}
            >
              <FaStar color="#FFA814" size={20} />
              <span
                style={{
                  fontWeight: 700,
                  lineHeight: '21px',
                }}
              >
                Nos avalie!
              </span>
            </div>
          }
          inverted
          buttonColor="#666666"
          style={{ borderRadius: 40 }}
          textStyle={{ fontWeight: 700 }}
          onClick={() => history.push('/app/review', { from: 'welcome' })}
        />
        <Button
          title="Acessar Cardápio"
          style={{ borderRadius: 40 }}
          textStyle={{ fontWeight: 700 }}
          onClick={handleNavigate}
          buttonColor="#c8131b"
        />
        {/* <button type="button" onClick={handleNavigate}>
         Acessar Cardápio
       </button> */}
      </Footer>
    </Container>
  );
};

export default WelcomeFromCode;
